.hide-border {
    border-color: transparent;
    background-clip: border-box;
}

.form-check {
    clear: both;
    display: flex;
    gap: 0.5rem;
    .form-check-input {
        width: 1.4rem;
        height: 1.4rem;
        flex: 0 0 auto;
        &:checked {
            background-color: var(--theme--btn--primary--bg-color--hover);
            border-color: var(--theme--btn--primary--bg-color--hover);
        }
        &[type="checkbox"] {
            border-radius: 0;
        }
    }
    .form-check-label {
        width: 100%;
        flex: 0 1 auto;
        color: var(--theme--color);
        padding-top: 0.2rem;
    }
}

.form-label {
    font-family: $alt-headings-font-family;
    font-weight: $alt-headings-font-weight;
    line-height: $alt-headings-line-height;
    letter-spacing: $alt-headings-letter-spacing;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: var(--theme--color--subtle);
}

.field-validation-error {
    display: block;
    color: #b21917;
    font-size: .875em;
    margin-top: .25rem;
    width: 100%;
}

.invalid-feedback {
    display: block;

    &.field-validation-valid {
        display: none;
    }
}

.input-char-limit {
    .input-char-limit--report {
        margin: 5px 0 0 0;
        text-align: right;
        font-size: 0.75rem;
        color: var(--theme--color--subtle);
    }
}

// pikaday

.pika-single {
    .pika-label:has(.pika-select) {
        padding: 5px 4px 5px 5px;
        margin: 0 2px;
        font-family: $alt-headings-font-family;
        font-weight: $alt-headings-font-weight;
        line-height: $alt-headings-line-height;
        letter-spacing: $alt-headings-letter-spacing;
        text-transform: uppercase;
        background-color: var(--theme--btn--primary--bg-color);
        border-color: var(--theme--btn--primary--bg-color);
        color: var(--theme--btn--primary--color);
        border-radius: $border-radius;
        &:after {
            display: inline-block;
            content: '';
            width: 0;
            height: 0;
            border-left: .25em solid transparent;
            border-right: .25em solid transparent;
            border-top: .4em solid currentColor;
            margin-left: .2em;
            vertical-align: .1em;
        }
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                background-color: var(--theme--btn--primary--bg-color--hover);
                border-color: var(--theme--btn--primary--bg-color--hover);
                color: var(--theme--btn--primary--color--hover);
            }
        }
    }
}
