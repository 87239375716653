:root {

    --vpHeight: 100vh;
    --vpWidth: 100vw;

    // ******************************
    // *****  layout variables  *****
    // ******************************

    --header--height: 80px;
    --edge-padding: 15px;

    @include media-breakpoint-up(sm) {
        --edge-padding: 30px;
    }

    @include media-breakpoint-up(lg) {
        --header--height: 100px;
    }

    @include media-breakpoint-up(xxl) {
        --header--height: 110px;
    }
    
    // ***********************************
    // *****  theming css variables  *****
    // ***********************************
    body.site-layout, .module--demo-area {
        // apply default theme rules
        --theme--background-color: #{$color--stone};
        @include on-light-theme-vars;
    }
    .module--demo-area {
        background: var(--theme--background-color);
        color: var(--theme--color);
        padding: 30px 0;
    }

}

// body

body.site-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: var(--theme--background-color);
    scroll-behavior: smooth;
    background-position: center top;
    padding-top: var(--header--height);

    #main-content {
        flex: 1 1 auto;
    }

    #main-footer {
        flex: 0 0 auto;
    }

    .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        padding-left: var(--edge-padding);
        padding-right: var(--edge-padding);
    }

}

// Link colours

a:not(.btn, .dropdown-item, .page-link, .visual-listing--item), .btn.btn-link {
    color: var(--theme--link--color);
    text-decoration: var(--theme--link--text-decoration--color);
    &:hover, &:focus {
        color: var(--theme--link--color--hover);
        text-decoration: var(--theme--link--text-decoration--hover);
    }
}

// rules

hr {
    border-color: var(--theme--rule--color);
    opacity: 1;
}

// align center until content wraps - then align left
.center-or-wrap-align-start {
    margin: 0 auto;
    width: fit-content;
    max-width: 100%;
    text-align: start;
}

// flipping

.flip-x:not(.flip-y) {
    transform: scaleX(-1);
}
.flip-y:not(.flip-x) {
    transform: scaleY(-1);
}
.flip-y.flip-x {
    transform: scaleX(-1) scaleY(-1);
}

// max-widths (mostly for form inputs
.mw-100px {
    max-width: 100px;
}
.mw-200px {
    max-width: 200px;
}
.mw-300px {
    max-width: 300px;
}
.mw-400px {
    max-width: 400px;
}
.mw-500px {
    max-width: 500px;
}

// emergency message

.emergency-message {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99999;
    border: 0;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    .fa-exclamation-triangle {
        color: currentColor;
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

// breadcrumb

.breadcrumb {
    background-color: transparent;
    font-size: 1rem;
    justify-content: center;
    margin-bottom: 1.5rem;
    font-size: .8rem;
    .breadcrumb-item {
        &:not(:first-child):before {
            content: '';
            display: inline-block;
            vertical-align: baseline;
            height: 100%;
            width: 1em;
            background: no-repeat url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512' class='svg-inline--fa fa-angle-right fa-w-6'%3E%3Cpath fill='%23DBC1A9' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z' class=''%3E%3C/path%3E%3C/svg%3E");
            background-size: auto 80%;
            background-position: left center;
        }
        a {
            color: var(--theme--color);
            &:hover, &:focus {
                color: var(--theme--link--color--hover);
                text-decoration: underline;
            }
        }
    }
}

// sticky content
.sticky-content {
    position: sticky;
    top: 30px;
    .product-gallery {
        position: relative;
        top: auto;
    }
}
