.hero {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    background-color: black;
    padding-bottom: #{map-get($hero--padding-bottom, xs)};
    // loop through each breakpoint
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            padding-bottom: #{map-get($hero--padding-bottom, $breakpoint)};
        }
    }
    .hero--image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
            display: block;;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
