﻿// ********************
// **  Site colours  **
// ********************

// main brand palette

$brand--sand:                   #efd5c0;
$brand--river:                  #002a3d;
$brand--bark:                   #5d3817;
$brand--forest:                 #787e50;

// expanded brand palette

$brand--kingfisher:             #4DA4B9;
$brand--harvest:                #F3BC3E;
$brand--apple:                  #BE6662;
$brand--leaf:                   #C7BA57;

// site extra colour variations

$brand--sand--light:            #e7d9cc;

$color--stone:                  #F8F6F3;

$color--grey:                   #929ba1;
$color--lighter-grey:           #d9dce0;
$color--lightest-grey:          #f0f1f2;


// ******************************************************************
// **                                                              **
// **   BOOTSTRAP VARIABLES - (overriding bootstrap defaults)      **
// **                                                              **
// ******************************************************************


// set fundamental bootstrap colour variables

$primary:                       $brand--apple;
$secondary:                     $brand--bark;
$light:                         $brand--sand;
$dark:                          $brand--river;

$text-muted:                    $brand--forest;

$success:                       $brand--leaf;
$info:                          $brand--kingfisher;
$warning:                       $brand--harvest;
$danger:                        $brand--apple;

$body-bg:                       white;
$body-color:                    $dark;

$link-color:                    $primary;
$link-decoration:               none;
$link-hover-color:              $brand--leaf;
$link-hover-decoration:         underline;


// Fonts

$font-family-sans-serif:      'Montserrat', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-family-base:            $font-family-sans-serif;
$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-weight-base:            400;
$line-height-base:            1.4;

$font-weight-bold:            700;

$headings-font-family:        'Volkhov', 'Times New Roman', Times, serif;
$headings-font-weight:        700;
$headings-line-height:        1.2;
$headings-font-style:         normal;
$headings-letter-spacing:     0;

$alt-headings-font-family:    'Barlow Condensed', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$alt-headings-font-weight:    600;
$alt-headings-line-height:    1.2;
$alt-headings-font-style:     normal;
$alt-headings-letter-spacing: 0.02em;

$h1-font-size:                $font-size-base * 2.4;
$h2-font-size:                $font-size-base * 1.6;
$h3-font-size:                $font-size-base * 1.45;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.1;
$h6-font-size:                $font-size-base;
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);

$display-font-sizes: (
  1: 3.6rem,
  2: 3.4rem,
  3: 3.2rem,
  4: 3rem,
  5: 2.8rem,
  6: 2.6rem
);

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        // added
        6: (
            $spacer * 4,
        ),
        // added
        7:
            (
                $spacer * 5,
            ),
        // added
        8:
            (
                $spacer * 6,
            )
            // added,
    ),
    $spacers
);

// for ecommerce element gaps etc
$ecommerce-spacer:            2px;

// Buttons

$border-radius:                 4px;
$border-radius-sm:              $border-radius;
$border-radius-lg:              $border-radius;

$input-btn-padding-y:           .65rem;
$btn-padding-x:                 1.25rem;

$input-btn-padding-y-sm:        .5rem;
$btn-padding-x-sm:              1rem;
$input-btn-font-size-sm:        1rem;

$input-btn-padding-y-lg:        0.85rem;
$btn-padding-x-lg:              1.5rem;
$input-btn-font-size-lg:        1.4rem;

$input-border-radius:           0;
$input-border-radius-sm:        $input-border-radius;
$input-border-radius-lg:        $input-border-radius;

$btn-font-family:               $alt-headings-font-family;
$btn-font-weight:               $alt-headings-font-weight;
$btn-font-size:                 1rem;

$dropdown-border-radius:        0;
$dropdown-bg:                   white;
$dropdown-link-hover-color:     $dark;
$dropdown-link-hover-bg:        rgba($brand--leaf, .5);

// Alerts

$alert-border-radius:         0;

// Cards

$card-border-width:                 0;
$card-border-radius:                0;
$card-cap-bg:                       transparent;
$card-bg:                           $light;



// ************************
// **                    **
// **   SITE VARIABLES   **
// **                    **
// ************************

// ************
// **  Hero  **
// ************


$hero--padding-bottom: (
    xs: 75%,
    sm: 75%,
    md: 56.25%,
    lg: 400px,
    xl: 450px,
    xxl: 500px,
);

// **************
// **  Panels  **
// **************

$panel--padding: (
    xs: 15px,
    sm: 20px,
    md: 20px,
    lg: 20px,
    xl: 25px,
    xxl: 30px
);

// *****************
// **  Ecommerce  **
// *****************

$ecommerce-scroll-limits--fallback:    50vh;
$ecommerce-scroll-limits--clamp:       clamp(200px, 50vh, 400px);

// ***********************
// **  Image galleries  **
// ***********************

$image-gallery--small-thumb-height: 120px;
$image-gallery--medium-thumb-height: 180px;
$image-gallery--large-thumb-height: 240px;
$image-gallery--thumb--margin: 5px;
$image-gallery--thumb--bg-color: $brand--river;

// *****************
// **  Expanders  **
// *****************

$expander--border-top:                      1px solid var(--theme--rule--color);
$expander--border-right:                    1px solid var(--theme--rule--color);
$expander--border-bottom:                   1px solid var(--theme--rule--color);
$expander--border-left:                     1px solid var(--theme--rule--color);
$expander--padding-top:                     15px;
$expander--padding-right:                   15px;
$expander--padding-bottom:                  15px;
$expander--padding-left:                    15px;
$expander--gap:                             1rem;
$expander--panel-divider--border-top:       1px solid var(--theme--rule--color);
$expander--panel-divider--padding-top:      15px;
